import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const PageStyles = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
`;

const StyledH1 = styled.h1`
  margin: 2rem;
`;

const UlStyles = styled.ul`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  list-style: none;
  margin: 3rem;
  padding: 0;
  width: 60vw;

  li {
    margin: 2rem;
  }

  a {
    font-size: 2rem;
    text-decoration: none;
    color: var(--orange);
    &:hover {
      color: var(--teal);
    }
  }
  @media (max-width: 767px) {
    width: 80vw;
    align-items: flex-start;
  }
`;

export default function FourOhFourPage() {
  return (
    <PageStyles>
      <StyledH1>You look lost..</StyledH1>
      <h3>But don't worry we're here to help!</h3>
      <UlStyles>
        <li>
          Take a look at what <Link to="/services">services</Link> we can offer
          you
        </li>
        <li>
          Check out what our <Link to="/testimonials">clients</Link> have to say
        </li>
        <li>
          Or get in <Link to="/contact">touch</Link> with us!
        </li>
      </UlStyles>
    </PageStyles>
  );
}
